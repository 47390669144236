import { createContext, ReactNode, useContext } from 'react';
import { TimeZone } from '../../lib/enum';
import { DateTime } from 'luxon';
import { datetime, DayConfig } from '../../lib/datettime';

const context = createContext<TimeZone | undefined>(undefined);

export function TimezoneContextProvider(props: { children: ReactNode; timezone?: TimeZone }): JSX.Element {
  return <context.Provider value={props.timezone}>{props.children}</context.Provider>;
}

export function useTz(): (config?: DayConfig) => DateTime {
  const tz = useContext(context);

  if (!tz) {
    throw new Error('Component is not within a TimezoneContextProvider');
  }

  return (config?: DayConfig): DateTime => {
    return datetime(config ?? new Date(), tz);
  };
}
